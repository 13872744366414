import "./menu.scss";

import { ThemeContext } from "../../context";
import { useContext } from "react";

const Menu = ({ menuOpen, setMenuOpen }) => {
  const theme = useContext(ThemeContext);
  // const darkMode = theme.state.darkMode;
  return (
    <div className={"menu " + (menuOpen && "active")}>
      <ul>
        <li onClick={() => setMenuOpen(false)}>
          <a href="#intro">Home</a>
        </li>

        <li onClick={() => setMenuOpen(false)}>
          <a href="#projects">Projects</a>
        </li>
        <li onClick={() => setMenuOpen(false)}>
          <a href="#about">About</a>
        </li>
        <li onClick={() => setMenuOpen(false)}>
          <a href="#contact">Contact</a>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
